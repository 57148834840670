import { useStore } from '@canalplus/one-navigation';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { offerLocationSelector } from '../../../selectors/application/application-selectors';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { isPhoneResolutionSelector } from '../../../store/slices/ui-selectors';
import { areAnimationsAutoplaySelector } from '../../../store/slices/user-selectors';
import { LocationStateContext } from '../../../typings/routing';
import { IShowcaseState } from '../data/formater';
import Showcase from './Showcase';

export type ShowcaseContainerProps = {
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  routingContext?: LocationStateContext;
  url: string;
} & FromProp;

function ShowcaseContainer({
  from,
  onClickParameters,
  onFocusable,
  routingContext,
  url,
}: ShowcaseContainerProps): JSX.Element | null {
  const [{ isLoading, error, data }] = useQueryTemplate<IShowcaseState>(url, {
    enableAlternateLinksUpdate: true,
    from,
    onClickParameters,
    routingContext,
    template: FetchRequestTypes.ShowcaseContainer,
  });

  // Reset the focus to default when data changes
  const store = useStore();
  useEffect(() => {
    if (onFocusable && data) {
      store.getActiveLayer().focus(undefined);
      onFocusable();
    }
  }, [onFocusable, store, data]);

  const areAnimationsAutoplay = useSelector(areAnimationsAutoplaySelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const isSmallScreenWidth: boolean = useSelector(isPhoneResolutionSelector);
  const offerLocation = useSelector(offerLocationSelector);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  if (!data) {
    return null;
  }
  const { strates, meta } = data;

  const descriptionMetas = [
    { name: 'description', content: meta.description },
    { property: 'og:description', content: meta.description },
  ];

  return (
    <>
      <Helmet title={meta?.title} meta={descriptionMetas} link={[{ rel: 'canonical', href: meta?.canonical }]} />
      <Showcase
        areAnimationsAutoplay={areAnimationsAutoplay}
        strates={strates}
        isTvDevice={isTvDevice}
        isSmallScreenWidth={isSmallScreenWidth}
        offerLocation={offerLocation}
      />
    </>
  );
}

export default ShowcaseContainer;
