import classNames from 'classnames';
import { useState } from 'react';
import { useDiveContext } from '../../context';
import {
  StatusAlertSvg,
  StatusCrossSvg,
  StatusErrorSvg,
  StatusInfoDefaultSvg,
  StatusInfoSvg,
  StatusValidSvg,
} from '../../exports/icons';
import { DiveSVG } from '../../types/Dive.types';
import { alertCVA, alertDeleteCVA, alertIconCVA, alertTextCVA } from './Alert.cva';
import { AlertIconsAriaLabels, AlertProps, AlertStatus, AlertVariant } from './Alert.types';

const STATUS_ICON = {
  [AlertStatus.Error]: StatusErrorSvg,
  [AlertStatus.Hint]: StatusInfoDefaultSvg,
  [AlertStatus.Info]: StatusInfoSvg,
  [AlertStatus.Success]: StatusValidSvg,
  [AlertStatus.Warning]: StatusAlertSvg,
} as const satisfies Record<AlertStatus, DiveSVG>;

const DEFAULT_ICONS_ARIA_LABELS = {
  [AlertStatus.Error]: 'Error',
  [AlertStatus.Hint]: 'Information',
  [AlertStatus.Info]: 'Information',
  [AlertStatus.Success]: 'Success',
  [AlertStatus.Warning]: 'Attention',
  close: 'Close',
} as const satisfies AlertIconsAriaLabels;

/**
 * The `Alert` component is designed to display information to the users,
 * presenting notifications and alerts related to various application statuses
 *
 * @example
 * <Alert
 *    status={AlertStatus.Error}
 *    message="This is an error message!"
 *    closable={true}
 * />
 */
export function Alert({
  className,
  closable = false,
  message,
  title,
  status = AlertStatus.Hint,
  variant = AlertVariant.AlertBox,
  iconsAriaLabels = DEFAULT_ICONS_ARIA_LABELS,
  'data-testid': dataTestId,
}: AlertProps): JSX.Element | null {
  const [isVisible, setIsVisible] = useState(true);
  const { isTv, device } = useDiveContext();

  const handleClose = () => {
    setIsVisible(false);
  };

  const StatusIcon = STATUS_ICON[status];
  const hasCloseIcon = closable && !isTv && variant === AlertVariant.AlertBox;

  if (!isVisible) return null;

  return (
    <div
      role={AlertStatus.Error || AlertStatus.Warning ? 'alert' : 'success'}
      data-testid={dataTestId}
      className={classNames(
        alertCVA({
          className,
          device,
          status,
          variant,
        })
      )}
    >
      <span
        className={classNames(
          alertIconCVA({
            device,
            variant,
            hasTitle: !!title,
          })
        )}
      >
        <StatusIcon role="img" aria-label={iconsAriaLabels[status]} />
      </span>
      <div className={alertTextCVA({ variant, device, hasTitle: !!title })}>
        {title && <span className="font-hind font-semibold">{title}</span>}
        {message && <span className="font-hind">{message}</span>}
      </div>
      {hasCloseIcon && (
        <button onClick={handleClose} type="button" className={alertDeleteCVA({ hasTitle: !!title })}>
          <StatusCrossSvg className="fill-op100 h-20 w-20" role="img" aria-label={iconsAriaLabels.close} />
        </button>
      )}
    </div>
  );
}
