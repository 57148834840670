import { DIMENSIONS, Ratio } from '@canalplus/mycanal-commons';
import { PosterPlaceholder, getRatioClassname } from '@canalplus/mycanal-sharedcomponent';
import { HashObjectReturn } from '@canalplus/mycanal-util-react';
import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import { ApiV2ContentGrid } from '@dce-front/hodor-types';
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { ShowcaseEvents } from '../../../constants/events';
import { getLocationStateContext } from '../../../helpers/contents/contents-helper';
import CardConnected from '../../Card/CardConnected';
import CardLogoChannel from '../../CardContents/CardLogoChannel/CardLogoChannel';
import Linker from '../../Linker/Linker';
import styles from './Tabs.css';

export type ContentTab = HashObjectReturn<ApiV2ContentGrid>;

export type ContentTabsProps = {
  activeTab?: any;
  descriptions: (string | string[] | undefined)[];
  tabs: ContentTab[];
  offerLocation: string;
};

function ContentTabs({ activeTab, tabs, descriptions, offerLocation }: ContentTabsProps): JSX.Element {
  useEffect(() => {
    if (Object.keys(activeTab.event).length) {
      const event = {
        data: {},
        name: ShowcaseEvents.CLICK_ON_TAB,
      };

      Object.defineProperty(event.data, ShowcaseEvents.TAB_TITLE, {
        enumerable: true,
        value: `${activeTab.displayName}`,
      });

      Object.defineProperty(event.data, 'app_zone', {
        value: offerLocation,
      });

      window.sendEvent?.(event);
    }
  });

  return (
    <>
      {tabs?.map((tab, index) => (
        <div
          className={classNames(styles.tabs__container, {
            [styles.tabs__activeContainer]: index === activeTab.index,
            [styles.tabs__inactiveContainer]: index !== activeTab.index,
          })}
          key={tab.hash}
        >
          {tab.contents?.map((content) => {
            const { displayParameters: { titleDisplayMode } = {} } = tab;
            const { onClick, URLImage = '' } = content;

            if (onClick) {
              return (
                <div className={styles.tabs__poster} key={content.contentID} data-ratio={Ratio.Ratio34}>
                  <Linker
                    data={{
                      ...content,
                      mainOnClick: onClick,
                      context: getLocationStateContext(onClick.displayTemplate),
                    }}
                    title={content.title}
                    id={content.contentID}
                  >
                    <CardConnected
                      altLogoChannel={content.altLogoChannel}
                      title={content.title}
                      subtitle={content.subtitle}
                      titleDisplayMode={
                        `${titleDisplayMode}` in TitleDisplayMode ? (titleDisplayMode as TitleDisplayMode) : undefined
                      }
                      image={{ default: URLImage }}
                      ratio={Ratio.Ratio34}
                      logoChannel={content.URLLogoChannel ? { default: content.URLLogoChannel } : undefined}
                      dimensions={DIMENSIONS['34'].small}
                    />
                  </Linker>
                </div>
              );
            } else {
              return (
                <div className={styles.tabs__poster} key={content.contentID} data-ratio={Ratio.Ratio34}>
                  <div className={getRatioClassname(Ratio.Ratio34)}>
                    {content.URLImage ? <img src={content.URLImage} alt={content.title} /> : <PosterPlaceholder />}
                    {content.URLLogoChannel && (
                      <CardLogoChannel logo={{ default: content.URLLogoChannel }} altImage={content.altLogoChannel} />
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      ))}
      {descriptions &&
        descriptions.map((description, index) => {
          return index === activeTab.index ? (
            <p className={styles.tabs__description} key={typeof description === 'string' ? description : index}>
              {description}
            </p>
          ) : null;
        })}
    </>
  );
}

export default memo(ContentTabs);
