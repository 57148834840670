import { AnchorHTMLAttributes, ReactElement } from 'react';
import { DiveIconPosition, DiveProps } from '../../types/Dive.types';

export enum LinkSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

/**
 * Props for the Link component.
 */
export type LinkProps = {
  /**
   * The content inside the link.
   */
  children: ReactElement | string;
  /**
   * Size of the link.
   *
   * @default "medium"
   */
  size?: `${LinkSize}`;
  /**
   * Whether the link is used as an icon.
   *
   * @default false
   */
  hasIcon?: boolean;
  /**
   * Whether the link color styles are reversed.
   *
   * @default false
   */
  isReversed?: boolean;
  /**
   * Whether the link is disabled or not.
   *
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Position of the `chevron` icon.
   *
   * See `hasIcon` prop on how to enable the icon display.
   *
   * @defaultValue 'right'
   */
  iconPosition?: `${DiveIconPosition}`;
  /**
   * The URL that the hyperlink points to.
   */
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  /**
   * Where to display the linked URL. Possible values are: `_blank`, `_self`, `_parent`, `_top`.
   */
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  /**
   * The id attribute for the link.
   */
  id?: AnchorHTMLAttributes<HTMLAnchorElement>['id'];
} & DiveProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;
