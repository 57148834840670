import { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import { memo } from 'react';
import { ButtonShapes } from '../../../constants/button';
import { TemplateTypes } from '../../../constants/templateTypes';
import Button from '../../Button/Button';
import styles from './SubscribeCTA.css';

const switchColor = (color: string): [any] => {
  switch (color) {
    case TemplateTypes.PRIMARY:
    case TemplateTypes.SECONDARY:
      return [styles[`subscribeCTA__button_${color}`]];
    default:
      return [styles[`subscribeCTA__button_${TemplateTypes.DEFAULT}`]];
  }
};

export type SubscribeCTAContent = {
  onClick: ApiV2OnClick;
  description: string;
  text: string[];
  label: string;
  type: string;
  style: string;
};

export type SubscribeCTAProps = {
  content: SubscribeCTAContent;
  id: string;
};

function SubscribeCTA({ content, id }: SubscribeCTAProps): JSX.Element {
  return (
    <div className={styles.subscribeCTA}>
      <Button
        id={id}
        shape={ButtonShapes.ROUND}
        text={content.label}
        link={content.onClick.URLWebsite}
        isBlank
        className={classNames(switchColor(content.style), styles.subscribeCTA__button)}
      />
      {content.text && <div className={styles.subscribeCTA__description}>{content.text.join(' ')}</div>}
      {content.description && <div className={styles.subscribeCTA__description}>{content.description}</div>}
    </div>
  );
}

export default memo(SubscribeCTA);
