import { cva } from 'class-variance-authority';
import { AlertStatus } from './Alert.types';

export const alertCVA = cva(['flex content-center w-auto rounded-2'], {
  variants: {
    variant: {
      alertBox: undefined,
      inputFieldAlertStandalone: ['items-start', 'sm:items-center'],
      inputFieldAlertAttached: ['items-start', 'sm:items-center'],
    },
    device: {
      web: 'p-16',
      tv: 'p-32',
    },
    status: {
      error: undefined,
      hint: undefined,
      info: undefined,
      success: undefined,
      warning: undefined,
    },
  },
  compoundVariants: [
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'tv',
      className: ['rounded-4', 'p-24'],
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className: 'p-8',
    },
    {
      variant: 'inputFieldAlertAttached',
      device: 'web',
      className: 'rounded-t-none',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'error',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-error',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'hint',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'info',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-info',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'success',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-success',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'warning',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-warning',
    },
    {
      variant: ['alertBox'],
      status: 'error',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-error',
    },
    {
      variant: ['alertBox'],
      status: 'hint',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box',
    },
    {
      variant: ['alertBox'],
      status: 'info',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-info',
    },
    {
      variant: ['alertBox'],
      status: 'success',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-success',
    },
    {
      variant: ['alertBox'],
      status: 'warning',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-warning',
    },
    {
      status: Object.values(AlertStatus),
      device: 'tv',
      className: 'bg-dt-theme-tv-surface-alert-alert',
    },
  ],
});

export const alertIconCVA = cva('flex', {
  variants: {
    variant: {
      alertBox: ['[&>svg]:w-20 [&>svg]:h-20'],
      inputFieldAlertStandalone: ['[&>svg]:w-16', '[&>svg]:h-16]'],
      inputFieldAlertAttached: ['[&>svg]:w-16', '[&>svg]:h-16]'],
    },
    device: {
      web: ['[&>svg]:w-20 [&>svg]:h-20'],
      tv: ['[&>svg]:w-36 [&>svg]:h-36'],
    },
    hasTitle: {
      true: 'self-start',
      false: ['mt-0 mb-0'],
    },
  },
  compoundVariants: [
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'tv',
      className: ['[&>svg]:w-40 [&>svg]:h-40'],
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className: ['[&>svg]:w-16 [&>svg]:h-16'],
    },
  ],
});

export const alertTextCVA = cva('flex flex-col mt-auto mb-auto', {
  variants: {
    variant: {
      alertBox: '[&>svg]:w-20 [&>svg]:h-20',
      inputFieldAlertStandalone: ['[&>svg]:w-16 [&>svg]:h-16]'],
      inputFieldAlertAttached: ['[&>svg]:w-16 [&>svg]:h-16]'],
    },
    device: {
      web: ['ml-12 mr-12'],
      tv: ['ml-24 mr-24 text-27'],
    },
    hasTitle: {
      true: 'leading-normal',
    },
  },
  compoundVariants: [
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className: 'text-14 text-dt-theme-text-input-field-alert-input-field-alert',
    },
    {
      variant: ['alertBox'],
      device: 'web',
      className: 'text-dt-theme-text-alert-box-alert-box',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'tv',
      className: 'text-dt-theme-tv-text-input-field-alert-input-field-alert',
    },
    {
      variant: ['alertBox'],
      device: 'tv',
      className: 'text-dt-theme-tv-text-alert-box-alert-box',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: ['tv', 'web'],
      hasTitle: false,
      className: 'leading-none',
    },
  ],
});

const defaultAlertDeleteStyle = [
  'border-0 outline-none bg-transparent p-0',
  'ml-auto cursor-pointer h-fit',
  '[&>svg]:fill-dt-theme-icon-alert-box-icon',
];

export const alertDeleteCVA = cva(defaultAlertDeleteStyle, {
  variants: {
    hasTitle: {
      true: 'self-top',
    },
  },
});
