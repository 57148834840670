import { Button, Link, LinkArrowRightSvg } from '@canalplus/dive';
import { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import Linker from '../../Linker/Linker';
import styles from './ButtonInline.css';

export type ButtonResources = {
  text: string;
  type: string;
};

export type ButtonInlineContent = {
  label: string;
  type: string;
  style: string;
  onClick: ApiV2OnClick;
};

export type ButtonInlineProps = {
  content: ButtonInlineContent;
  id: string;
};

function ButtonInline({ content, id }: ButtonInlineProps): JSX.Element {
  const { label } = content || {};
  const isTvDevice = useSelector(displayTVModeSelector);

  return (
    <div className={classNames(styles.buttonInline)}>
      <Linker
        id={id}
        data={{ mainOnClick: content.onClick }}
        renderWrapper={(linkerSettings) => {
          const { onClick, href, target } = linkerSettings;
          return isTvDevice ? (
            <Button variant="text" font="hind" icon={<LinkArrowRightSvg />} onClick={onClick} id={id}>
              {label}
            </Button>
          ) : (
            <Link onClick={onClick} href={href} hasIcon iconPosition="left" target={target} id={id}>
              {label}
            </Link>
          );
        }}
      />
    </div>
  );
}

export default memo(ButtonInline);
