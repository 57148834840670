import { DiveProps } from '../../types/Dive.types';

export enum AlertStatus {
  Error = 'error',
  Hint = 'hint',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum AlertVariant {
  AlertBox = 'alertBox',
  InputFieldAlertStandalone = 'inputFieldAlertStandalone',
  InputFieldAlertAttached = 'inputFieldAlertAttached',
}

export type AlertIconsAriaLabels = Partial<Record<AlertStatus | 'close', string>>;

export type AlertProps = {
  /**
   * The textual content of the alert to be displayed to the user.
   * This prop is required to convey the necessary information or notification
   * details to the user.
   */
  message: string;
  /**
   * If the alert can be manually closed by the user with close icon.
   *
   * Note: Requires 'variant='alertBox' to display the icon; otherwise, it has no effect.
   * @defaultValue false
   */
  closable?: boolean;
  /**
   * The status type of the alert, determining its thematic styling and icon.
   *
   * Acceptable values include "error", "hint", "info", "success", and "warning".
   * If omitted, "hint" status will be applied.
   *
   */
  status?: `${AlertStatus}`;
  /**
   * Custom ARIA labels for the statuses and close icon.
   * Defaults to ARIA labels in English.
   */
  iconsAriaLabels?: AlertIconsAriaLabels;
  /**
   * An optional title for the alert to display above the message.
   *
   */
  title?: string;
  /**
   * Specifies the visual variant of the alert, determining its structural styling.
   *
   * Possible values include "alertBox", "inputFieldAlertStandalone", and "inputFieldAlertAttached".
   * If omitted, "alertBox" variant will be applied.
   *
   */
  variant?: `${AlertVariant}`;
} & DiveProps;
