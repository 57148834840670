import { Tabs } from '@canalplus/mycanal-sharedcomponent';
import { ApiV2TabsContentStrate } from '@dce-front/hodor-types/api/v2/page/dtos/strates/tabs/manual';
import { memo } from 'react';
import ContentTabs, { ContentTab } from './ContentTabs';

export type TabsProps = {
  contents: ApiV2TabsContentStrate[];
  offerLocation: string;
  tabs: ContentTab[];
};

function ShowcaseTabs({ contents, tabs, offerLocation }: TabsProps): JSX.Element {
  const tabsNames = contents.map((content) => content.displayName).filter((displayName) => !!displayName);
  const descriptions = contents.map((content) => content.description);

  return (
    <Tabs tabsName={tabsNames}>
      <ContentTabs tabs={tabs} descriptions={descriptions} offerLocation={offerLocation} />
    </Tabs>
  );
}

export default memo(ShowcaseTabs);
