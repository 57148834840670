import { cva } from 'class-variance-authority';

export const linkCVA = cva(['font-hind font-semibold group'], {
  variants: {
    isReversed: {
      true: 'text-dt-theme-link-link-default',
      false: 'text-dt-theme-link-link-accent',
    },
    isDisabled: { true: 'opacity-dt-opacity-50 cursor-not-allowed' },
    hasIcon: {
      true: 'flex items-center no-underline',
      false: 'underline',
    },
    size: {
      small: 'text-14',
      medium: 'text-16',
      large: 'text-18',
    },
    iconPosition: {
      left: undefined,
      right: undefined,
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isReversed: true,
      className:
        'group-hover:text-dt-theme-link-link-hover group-active:text-dt-theme-link-link-pressed group-active:decoration-dt-theme-link-link-pressed',
    },
    {
      isDisabled: false,
      isReversed: false,
      className: 'group-hover:text-dt-theme-link-link-accent-pressed group-active:text-dt-theme-link-link-accent-hover',
    },
    {
      hasIcon: true,
      iconPosition: 'left',
      className: 'flex-row-reverse justify-end',
    },
  ],
});

export const linkIconCVA = cva('', {
  variants: {
    isDisabled: {
      true: 'opacity-dt-opacity-50 cursor-not-allowed',
    },
    isReversed: {
      true: 'fill-dt-theme-icon-link-link-icon',
      false: 'fill-dt-theme-icon-link-link-icon-accent',
    },
    size: {
      small: 'w-[1rem] h-[1rem]',
      medium: 'w-[1.125rem] h-[1.125rem]',
      large: 'w-[1.25rem] h-[1.25rem]',
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isReversed: true,
      className:
        'group-hover:fill-dt-theme-icon-link-link-icon-hover group-active:fill-dt-theme-icon-link-link-icon-pressed',
    },
    {
      isDisabled: false,
      isReversed: false,
      className:
        'group-hover:fill-dt-theme-icon-link-link-icon-accent-pressed group-active:fill-dt-theme-icon-link-link-icon-accent-hover',
    },
  ],
});
